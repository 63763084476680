import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

const TermsPage = () => (
  <Layout>
    <Seo title="Privacy Policy" />
    <div className="bg-gray-50 py-12">
      <div className="w-11/12 max-w-2xl mx-auto">
        <h2 className="text-site-lightblue text-3xl text-center font-bold mb-6">Terms of Service</h2>
        <p className='text-gray-800 mb-4'>1. Terms</p>
        <p className='text-gray-800 mb-4'>By accessing the website at https://itproactive.com/, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.</p>

        <p className='text-gray-800 mb-4'>2. Use License</p>
        <p className='text-gray-800 mb-4'>Permission is granted to temporarily download one copy of the materials (information or software) on IT Proactive's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>
        <ul className="list-inside list-disc mb-4">
          <li className='text-gray-800 mb-4'>modify or copy the materials;</li>
          <li className='text-gray-800 mb-4'>use the materials for any commercial purpose, or for any public display (commercial or non-commercial);</li>
          <li className='text-gray-800 mb-4'>attempt to decompile or reverse engineer any software contained on IT Proactive's website;</li>
          <li className='text-gray-800 mb-4'>remove any copyright or other proprietary notations from the materials; or</li>
          <li className='text-gray-800 mb-4'>transfer the materials to another person or "mirror" the materials on any other server.</li>
        </ul>
        <p className='text-gray-800 mb-4'>This license shall automatically terminate if you violate any of these restrictions and may be terminated by IT Proactive at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.</p>

        <p className='text-gray-800 mb-4'>3. Disclaimer</p>
        <p className='text-gray-800 mb-4'>The materials on IT Proactive's website are provided on an 'as is' basis. IT Proactive makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. Further, IT Proactive does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>

        <p className='text-gray-800 mb-4'>4. Limitations</p>
        <p className='text-gray-800 mb-4'>In no event shall IT Proactive or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on IT Proactive's website, even if IT Proactive or a IT Proactive authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you.</p>

        <p className='text-gray-800 mb-4'>5. Accuracy of materials</p>
        <p className='text-gray-800 mb-4'>The materials appearing on IT Proactive website could include technical, typographical, or photographic errors. IT Proactive does not warrant that any of the materials on its website are accurate, complete or current. IT Proactive may make changes to the materials contained on its website at any time without notice. However IT Proactive does not make any commitment to update the materials.</p>

        <p className='text-gray-800 mb-4'>6. Links</p>
        <p className='text-gray-800 mb-4'>IT Proactive has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by IT Proactive of the site. Use of any such linked website is at the user's own risk.</p>

        <p className='text-gray-800 mb-4'>7. Modifications</p>
        <p className='text-gray-800 mb-4'>IT Proactive may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service.</p>

        <p className='text-gray-800 mb-4'>8. Governing Law</p>
        <p className='text-gray-800 mb-4'>These terms and conditions are governed by and construed in accordance with the laws of California and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.</p>
      </div>
    </div>
  </Layout>
)

export default TermsPage
